import "./styles.css";
import { useLocale } from "../../utils/i18n";
import Markdown from "react-markdown";
import enMD from "./en.md";
import zhHansMD from "./zh-Hans.md";
import zhHantMD from "./zh-Hant.md";
import { useEffect, useState } from "react";

const mdMap = {
  en: enMD,
  "zh-Hans": zhHansMD,
  "zh-Hant": zhHantMD,
};

export default function Terms() {
  const { locale } = useLocale();
  const [mdContent, setMdContent] = useState<string>("");
  useEffect(() => {
    const file = mdMap[locale] ?? zhHansMD;
    fetch(file)
      .then((res) => res.text())
      .then((text) => {
        setMdContent(text);
      });
  }, [locale]);
  return (
    <main className="background">
      <Markdown>{mdContent}</Markdown>
    </main>
  );
}
